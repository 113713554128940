import { mapGetters } from "vuex";
import * as types from "./../../store/types";
import $formatNumber from "./../../mixins/formatNumber";
import { ASSETS_URL } from "./../../services/base";

export default {
    name: "dashboard-currencies",
    components: {},
    props: ["columnNumber"],
    mixins: [$formatNumber],
    data() {
        return {
            baseURL: ASSETS_URL,
        };
    },
    computed: {
        ...mapGetters({
            currencies: types.GET_CURRENCIES,
        }),
    },
    mounted() {},
    methods: {
        calcRate(from, to) {
            let fromObj = this.currencies.find(
                (element) => element.iso == from
            );
            let toObj = this.currencies.find((element) => element.iso == to);
            let fromPrice = fromObj.price;
            let toPrice = toObj.price;
            this.mainRate = toPrice / fromPrice;
            return toPrice / fromPrice;
        },
    },
};
